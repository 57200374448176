import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy policy" />
    <div className="container">
      <h1>Privacy policy</h1>
      <p>
        Our privacy policy is short but clear. Any information that you divulge
        to us is kept in confidence. We do not collect personally identifiable
        information on our website, or otherwise unless you choose to give it to
        us via an electronic mail message, a phone call, by filling out one of
        the forms on our site, or by postal mail. Even when you choose to give
        us information, we keep it confidential. We do not sell, rent, share, or
        otherwise disclose mailing lists or other personally identifiable
        information. We maintain some records of individuals who contact us in
        case we need to contact you later or provide further information in the
        future. If you have any questions or concerns about this privacy policy,
        please feel free to contact us.
      </p>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
